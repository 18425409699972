import './EasySoftwareSideCard.scss'

import { useLocation } from '@reach/router'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React, { FunctionComponent } from 'react'

import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareSideCardTrialType {
  contentType: 'EasySoftwareSideCardTrial'
  displayText: string
  contentItemId: string
  sideCardTrialText: PuxWysiwygHtmlBodyType
  sideCardTrialButtonText: string
}

const rootClass = `sidebar`

const EasySoftwareSideCardTrial: FunctionComponent<EasySoftwareSideCardTrialType> = (
  props
) => {
  return (
    <div className={`${rootClass}-box`}>
      <h4 className={`${rootClass}-box-title`}>{props.displayText}</h4>
      <PuxWysiwyg
        className={`${rootClass}-box-perex perex`}
        content={props.sideCardTrialText}
      />
      <AnchorLink
        className='pux-button pux-button-filled pux-button-primary'
        to={`${useLocation().pathname ?? ``}#trial`}
      >
        {props.sideCardTrialButtonText}
      </AnchorLink>
    </div>
  )
}

export default EasySoftwareSideCardTrial
